<template>
  <div class="preference-type">
    <div>
      <div v-if="!validValues?.length">
        <span>
          No values found.
        </span>
      </div>
      <div
        v-else
        class="preference-type-collection__items"
      >
        <table>
          <thead
            class="preference-type-collection__table-title"
          >
            <th>
              Value
            </th>
            <th>
              <span
                class="preference-type-collection__table-title_remove-all"
                @click="onRemoveAllClicked"
              >
                Delete all
              </span>
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in validValues"
              :id="'tr' + index"
              :ref="el => { if (el) vForDivs[index] = el }"
              :key="index"
              class="preference-type-collection__item"
              data-arrow-navigable-item
            >
              <td
                class="preference-type-collection__item_value_td"
              >
                <div
                  v-if="validBaseType === 'Integer'"
                  class="preference-type-collection__item_value"
                >
                  <input
                    :id="'valueInput' + index"
                    :value="displayAsNumber(item.value)"
                    type="number"
                    @input="item.value = stringFromNumber($event.target.value)"
                  >
                </div>
                <div
                  v-else-if="validBaseType === 'Double'
                    || validBaseType === 'Decimal'"
                  class="preference-type-collection__item_value"
                >
                  <input
                    :id="'valueInput' + index"
                    :value="displayAsNumber(item.value)"
                    type="number"
                    step="0.1"
                    @input="item.value = stringFromNumber($event.target.value)"
                  >
                </div>
                <div
                  v-else
                >
                  <template
                    v-if="customEditor === 'RtfEditor'"
                  >
                    <RtfViewer
                      :name="`${preferenceName} (${index + 1}/${validValues.length})`"
                      :rtf="item.value"
                      :edit="true"
                      @rtf-uploaded="(content) => { item.value = content; }"
                    />
                  </template>
                  <template
                    v-else
                  >
                    <textarea
                      :id="'valueInput' + index"
                      :ref="el => setIsTextLongArray(el, index)"
                      v-model="item.value"
                      placeholder="Type value..."
                      class="preference-type-collection__item_value_textarea"
                      type="text"
                      :rows="isTextLong[index] ? 2 : 1"
                      @focus="onTextareaFocus(index)"
                      @input="onTextareaFocus(index)"
                    />
                    <div
                      v-if="isTextLong[index]"
                      class="preference-type-collection__input_item_show_buttons"
                    >
                      <span
                        v-if="isTextLong[index] && !isTextareaOpen[index]"
                        @click="onShowMoreClicked(index)"
                      >
                        Show more
                      </span>
                      <span
                        v-else-if="isTextLong[index] && isTextareaOpen[index]"
                        @click="onShowLessClicked(index)"
                      >
                        Show less
                      </span>
                    </div>
                  </template>
                </div>
              </td>
              <td>
                <div
                  class="preference-type-collection__cross-icon_wrapper"
                >
                  <span
                    class="preference-type-collection__cross-icon"
                    @click="onCrossClicked(item)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddButton
      type="secondary"
      text="Add value"
      small
      :ssr-icon="plus"
      @click="onAddClicked"
    />
  </div>
</template>

<script>
import {
  computed, watch, reactive, ref, nextTick,
} from 'vue';

import RtfViewer from '@/components/shared/rtf-viewer.vue';

import AddButton from '@ingka/button-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

import { useUtilsStore } from '@/store/utils';

export default {
  components: {
    AddButton,
    RtfViewer,
  },

  props: {
    preferenceValueDTO: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    console.log('Init type edit collection');

    const { toHash } = useUtilsStore();

    const vForDivs = ref([]);
    const newItemAdded = ref(false);

    const validValues = computed(() => props.preferenceValueDTO.values);
    const validBaseType = computed(() => props.preferenceValueDTO.baseType);
    const customEditor = computed(() => props.preferenceValueDTO.customEditor);
    const preferenceName = computed(() => props.preferenceValueDTO.name);

    console.log('validValues', validValues.value);
    console.log('validBaseType', validBaseType.value);

    const displayAsNumber = (value) => Number(value);
    const stringFromNumber = (value) => value.toString();

    const isTextLong = reactive([]);

    const isTextareaOpen = reactive([]);
    const twoRowsHeight = 42;

    const setIsTextLongArray = (el, index) => {
      isTextLong[index] = el?.scrollHeight > twoRowsHeight;
    };

    const onCrossClicked = (item) => {
      // TODO: Check to select correct index if same value
      const index = validValues.value.indexOf(item);
      validValues.value.splice(index, 1);
    };

    const onRemoveAllClicked = () => {
      validValues.value.splice(0, validValues.value?.length);
    };

    const getDefaultInitialValue = () => {
      switch (validBaseType.value) {
        case 'Boolean':
        case 'Integer':
        case 'Decimal':
        case 'Double':
          return '0';
        default:
          return '';
      }
    };

    const onAddClicked = () => {
      validValues.value.push(reactive({ value: getDefaultInitialValue() }));
      newItemAdded.value = true;
    };

    const onTextareaFocus = (index) => {
      const inputElement = document.getElementById(`valueInput${index}`);
      const trElement = document.getElementById(`tr${index}`);
      if (inputElement?.scrollHeight > twoRowsHeight) {
        inputElement.style.height = `${inputElement?.scrollHeight}px`;
        isTextareaOpen[index] = true;
        trElement.style.verticalAlign = 'top';
      }
    };

    const onShowMoreClicked = (index) => {
      onTextareaFocus(index);
    };

    const onShowLessClicked = (index) => {
      const inputElement = document.getElementById(`valueInput${index}`);
      const trElement = document.getElementById(`tr${index}`);
      inputElement.style.height = `${twoRowsHeight / 16}rem`;
      isTextareaOpen[index] = false;
      trElement.style.verticalAlign = 'inherit';
    };

    const onTextareaBlur = (e) => {
      e.target.style.height = '1.3110625rem';
    };

    watch(() => validValues.value, async () => {
      if (newItemAdded.value) {
        newItemAdded.value = false;
        await nextTick();
        vForDivs.value.undefined.firstChild.focus();
      }
    },
    { deep: true });

    return {
      displayAsNumber,
      stringFromNumber,
      vForDivs,
      validValues,
      validBaseType,
      customEditor,
      preferenceName,
      plus: Icons.plus,
      toHash,
      onCrossClicked,
      onAddClicked,
      onTextareaFocus,
      onTextareaBlur,
      setIsTextLongArray,
      onShowMoreClicked,
      onShowLessClicked,
      isTextLong,
      isTextareaOpen,
      onRemoveAllClicked,
    };
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

.preference-type-collection__table-title {
  font-size: 0.75rem;
  font-weight: normal;
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.preference-type-collection__table-title > th {
  font-size: 0.75rem;
  font-weight: normal;
}

.preference-type-collection__table-title_remove-all {
  font-weight: bold;
}

.preference-type-collection__table-title_remove-all:hover {
  cursor: pointer;
}

.preference-type-collection__items {
  padding-bottom: 1rem;
}

.preference-type-collection__item {
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.preference-type-collection__item > td {
  padding: 0.75rem 0;
}

.preference-type-collection__item_value > input {
  border: none;
}

.preference-type-collection__item_value_textarea {
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  color: var(--colour-text-dark);
  resize: none;
  border: 0;
}

.preference-type-collection__item_value_td {
  width: 100%;
  vertical-align: inherit;
}

.preference-type-collection__input_item_show_buttons {
  padding-top: 0.5rem;
  text-decoration: underline;
}

.preference-type-collection__input_item_show_buttons:hover {
  color: var(--colour-text-dark);
  cursor: pointer;
}

.preference-type-collection__cross-icon {
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: transparent;
  background-image: url("../../../../assets/svg/cross-light.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.preference-type-collection__cross-icon:hover {
  background-image: url("../../../../assets/svg/cross.svg");
}

.preference-type-collection__cross-icon_wrapper {
  padding: 0 1.5rem;
}
</style>
